import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Heading,
  MaterialUICore,
  MaterialIcons,
  Button,
  Switch,
  Box,
  Body,
} from '@iclinic/design-system';

import { ResponsiveCard } from 'features/onlinePayment/components';
import {
  DocumentProofRawData,
  SellerRawData,
} from 'features/onlinePayment/services/config/types';
import { SellerType } from 'features/onlinePayment/containers/config/seller/constants';
import { SellerStatus } from 'features/onlinePayment/state/constants';
import { isSellerFullyCreated } from 'features/onlinePayment/state/config/register/sagas';
import StatusChip from './StatusChip';
import { getStatuses } from './sellerStatus';
import * as S from './styles';
import StatusIcon from './StatusIcon';
import { getSellerProfiles } from 'features/onlinePayment/state/config/register/selectors';
import { updateDefaultSeller } from 'features/onlinePayment/state/config/register';

const { List, ListItem, ListItemText } = MaterialUICore;
const { Person, PersonPinRounded, Room, MonetizationOn, CreditCard } =
  MaterialIcons;

type AccountCardProps = {
  onClick?: (type: SellerType, profile?: SellerRawData | null) => void;
  profile?: SellerRawData | null;
  documents?: DocumentProofRawData[] | null;
  type: SellerType;
};

export default function AccountCard({
  profile,
  type,
  onClick,
  documents,
}: AccountCardProps) {
  const dispatch = useDispatch();
  const profiles = useSelector(getSellerProfiles);

  const title = type === SellerType.PF ? 'Pessoa física' : 'Pessoa jurídica';
  const classes = S.useStyles();
  const statuses = getStatuses(profile, documents);
  const completed = isSellerFullyCreated(profile);
  const status =
    !completed && profile?.status ? SellerStatus.Pending : profile?.status;
  const isDefault = profile?.is_default;
  const activeDefaultAccountSwitch = profiles?.business && profiles?.individual;

  const handleChangeDefaultSeller = () => {
    if (profile && !isDefault) {
      dispatch(updateDefaultSeller(profile.id));
    }
  };

  return (
    <ResponsiveCard variant="outlined" className={classes.card}>
      <div>
        <Heading variant="xl" component="h2">
          {title}
        </Heading>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          minHeight={38}
        >
          <StatusChip sellerStatus={status} />
          {profile?.status === SellerStatus.Approved && (
            <Switch
              label="Conta padrão"
              formControlLabelProps={{ className: classes.switch }}
              checked={isDefault}
              disabled={!activeDefaultAccountSwitch}
              onChange={handleChangeDefaultSeller}
            />
          )}
        </Box>
        <List className={classes.menu}>
          <ListItem disableGutters>
            <S.ListItemIcon>
              <StatusIcon status={statuses.profile} icon={<Person />} />
            </S.ListItemIcon>
            <ListItemText primary="Perfil" />
          </ListItem>
          <ListItem disableGutters>
            <S.ListItemIcon>
              <StatusIcon status={statuses.profile} icon={<Room />} />
            </S.ListItemIcon>
            <ListItemText primary="Endereço" />
          </ListItem>
          {type === SellerType.PJ && (
            <ListItem disableGutters>
              <S.ListItemIcon>
                <StatusIcon
                  status={statuses.profile}
                  icon={<PersonPinRounded />}
                />
              </S.ListItemIcon>
              <ListItemText primary="Dono da clínica" />
            </ListItem>
          )}
          <ListItem disableGutters>
            <S.ListItemIcon>
              <StatusIcon
                status={statuses.bankAccount}
                icon={<MonetizationOn />}
              />
            </S.ListItemIcon>
            <ListItemText primary="Conta bancária" />
          </ListItem>
          <ListItem disableGutters>
            <S.ListItemIcon>
              <StatusIcon
                status={statuses.paymentSettings}
                icon={<CreditCard />}
              />
            </S.ListItemIcon>
            <ListItemText primary="Forma de pagamento" />
          </ListItem>
        </List>
      </div>
      <S.Footer>
        {profile?.status !== SellerStatus.Deleted ? (
          <Button onClick={() => onClick?.(type, profile)}>
            {profile ? 'Atualizar cadastro' : 'Criar cadastro'}
          </Button>
        ) : (
          <Body variant="xxs" bold>
            Entre em contato com o suporte
          </Body>
        )}
      </S.Footer>
    </ResponsiveCard>
  );
}

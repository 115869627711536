import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { Physician, PlansSuccess, Product } from '../../services/types';
import { PhysiciansByAddon, SubscriptionFormData } from './types';

export interface PlansState {
  status?: RequestStatus;
  products?: PlansSuccess;
  physicians?: Physician[];
  mostSeller?: string;
  isAnnual: boolean;
  offCanvasOpen?: boolean;
  selectedPlan?: Product;
  selectedAddons?: Product[];
  physiciansByAddon?: PhysiciansByAddon;
  subscriptionStatus?: RequestStatus;
  flipCard: boolean;
  subscriptionStepMapper?: string[];
  currentSubscriptionStep?: string;
  errorsMessage?: string[];
}

export const initialState: PlansState = {
  status: RequestStatus.Pending,
  mostSeller: 'plus',
  selectedAddons: [],
  isAnnual: false,
  offCanvasOpen: false,
  flipCard: false,
};

export const nameStore = '@webapp/checkout';

export const fetchCheckoutData = createAction(`${nameStore}/fetchCheckoutData`);

export const fetchCheckoutProducts = createAction(
  `${nameStore}/fetchCheckoutProducts`,
);

export const selectAddonTrigger = createAction<Product>(
  `${nameStore}/selectAddonTrigger`,
);

export const triggerSubscription = createAction<SubscriptionFormData>(
  `${nameStore}/triggerSubscription`,
);

export const triggerVerifyInitialStep = createAction(
  `${nameStore}/triggerVerifyInitialStep`,
);

export const changeSubscriptionStep = createAction(
  `${nameStore}/changeSubscriptionStep`,
);

const PlansSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    fetchCheckoutProductsSuccess: (
      state,
      action: PayloadAction<{
        products: PlansSuccess;
      }>,
    ): PlansState => ({
      ...state,
      status: RequestStatus.Success,
      products: action.payload.products,
    }),
    fetchCheckoutDataSuccess: (
      state,
      action: PayloadAction<{
        products: PlansSuccess;
        physicians: Physician[];
      }>,
    ): PlansState => ({
      ...state,
      status: RequestStatus.Success,
      products: action.payload.products,
      physicians: action.payload.physicians,
    }),
    fetchCheckoutDataFailure: (state): PlansState => ({
      ...state,
      status: RequestStatus.Error,
    }),
    toggleAnnualOrMonthlyPaymentRecurrence: (state) => ({
      ...state,
      isAnnual: !state.isAnnual,
    }),
    setIsAnnual: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isAnnual: action.payload,
    }),
    updateSelectedPlan: (state, action: PayloadAction<Product>) => ({
      ...state,
      selectedPlan: action.payload,
    }),
    updateSelectedPhysicians: (
      state,
      action: PayloadAction<PhysiciansByAddon | undefined>,
    ) => ({
      ...state,
      physiciansByAddon: action.payload && {
        ...state.physiciansByAddon,
        ...action.payload,
      },
    }),
    updateSubscriptionStatus: (
      state,
      action: PayloadAction<RequestStatus>,
    ) => ({
      ...state,
      subscriptionStatus: action.payload,
    }),
    toggleOffCanvasOpenClose: (state) => ({
      ...state,
      offCanvasOpen: !state.offCanvasOpen,
    }),
    toggleFlipCard: (state) => ({
      ...state,
      flipCard: !state.flipCard,
    }),
    updateSelectedAddons: (state, action: PayloadAction<Product[]>) => ({
      ...state,
      selectedAddons: action.payload,
    }),
    updateSubscriptionStepMapper: (
      state,
      action: PayloadAction<string[] | undefined>,
    ) => ({
      ...state,
      subscriptionStepMapper: action.payload,
    }),
    updateCurrentSubscriptionStep: (
      state,
      action: PayloadAction<string | undefined>,
    ) => ({
      ...state,
      currentSubscriptionStep: action.payload,
    }),
    setErrorsMessage: (state, action: PayloadAction<string[] | undefined>) => ({
      ...state,
      errorsMessage: action.payload,
    }),
  },
});

export const {
  fetchCheckoutDataSuccess,
  fetchCheckoutDataFailure,
  toggleAnnualOrMonthlyPaymentRecurrence,
  updateSelectedPlan,
  updateSelectedAddons,
  updateSelectedPhysicians,
  updateSubscriptionStatus,
  toggleOffCanvasOpenClose,
  toggleFlipCard,
  updateSubscriptionStepMapper,
  updateCurrentSubscriptionStep,
  setErrorsMessage,
  setIsAnnual,
  fetchCheckoutProductsSuccess,
} = PlansSlice.actions;

export default PlansSlice.reducer;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Heading,
  MaterialIcons,
  TextField,
  CircularProgress,
  Button,
  MaterialUICore,
  Body,
} from '@iclinic/design-system';

import * as S from './styles';
import {
  getPharmas,
  isFetchingPharmas,
  isOffCanvasOpen,
} from 'features/free-samples/state/opt-in-confirmation/selectors';
import {
  closeOptinOffCanvas,
  fetchPharmasData,
  sendIntercomEvent,
  setOptInConfirmation,
  setSelectedPharmas,
} from 'features/free-samples/state/opt-in-confirmation';
import { Pharmaceutical } from 'features/free-samples/services/types';

const { Close, SearchOutlined } = MaterialIcons;
const { List, ListItemText } = MaterialUICore;

const PharmasOffCanvas = () => {
  const isOpen = useSelector(isOffCanvasOpen);

  const dispatch = useDispatch();
  const isFetching = useSelector(isFetchingPharmas);
  const pharmas = useSelector(getPharmas);

  useEffect(() => {
    dispatch(fetchPharmasData());
  }, [dispatch]);

  const handleCloseOffCanvas = () => {
    dispatch(closeOptinOffCanvas());
  };

  const [checkedPharmaceuticals, setCheckedPharmaceuticals] = useState<
    Pharmaceutical[]
  >([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setCheckedPharmaceuticals(pharmas);
  }, [pharmas]);

  const onPhysicianClick = (pharma: Pharmaceutical) => {
    setCheckedPharmaceuticals((previousState) => {
      const hasPharma = previousState.find((item) => item.id === pharma.id);

      if (hasPharma) {
        return previousState.filter((item) => item.id !== pharma.id);
      }

      return [...previousState, pharma];
    });
  };

  const onSelectAllPhysiciansClick = () => {
    setCheckedPharmaceuticals((previous) => {
      if (previous.length === pharmas.length) {
        return [];
      }

      return pharmas;
    });
  };

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSearch(value);
  };

  const onSubmitSelections = () => {
    dispatch(setSelectedPharmas(checkedPharmaceuticals));
    dispatch(setOptInConfirmation());
    dispatch(sendIntercomEvent('optin_form_completed'));
  };

  return (
    <S.Drawer
      open={isOpen}
      onClose={handleCloseOffCanvas}
      anchor="right"
      elevation={0}
      data-testid="pharmas-offcanvas"
    >
      <S.Content>
        <S.Header>
          <Heading variant="sm" gutterBottom>
            Selecione os laboratórios
          </Heading>

          <S.CloseIconButton
            aria-label="Botão fechar"
            size="small"
            onClick={handleCloseOffCanvas}
          >
            <Close />
          </S.CloseIconButton>
        </S.Header>

        <S.Description variant="xs">
          Selecione de quais laboratórios deseja receber amostras:
        </S.Description>

        <S.ChipContainer>
          {isFetching ? (
            <CircularProgress />
          ) : (
            <S.SelectPharmasWrapper>
              <S.SearchFieldWrapper>
                <TextField
                  fullWidth
                  value={search}
                  onChange={onChangeSearch}
                  label="Buscar por laboratório"
                  InputProps={{
                    endAdornment: <SearchOutlined />,
                  }}
                />
              </S.SearchFieldWrapper>

              <S.ListWrapper>
                <List>
                  <S.ListItem button onClick={onSelectAllPhysiciansClick} dense>
                    <S.ListItemIcon>
                      <S.CheckboxListItem
                        edge="start"
                        size="medium"
                        color="primary"
                        disableRipple
                        checked={
                          checkedPharmaceuticals.length === pharmas.length
                        }
                      />
                    </S.ListItemIcon>

                    <ListItemText
                      primary={
                        <Body variant="xxs">
                          Selecionar todos os laboratórios
                        </Body>
                      }
                    />
                  </S.ListItem>

                  {pharmas
                    .filter((pharma) =>
                      pharma.name.toLowerCase().includes(search.toLowerCase()),
                    )
                    .map((pharma) => (
                      <S.ListItem
                        button
                        key={pharma.name}
                        data-testid={`pharma-checkbox-${pharma.id}`}
                        onClick={() => onPhysicianClick(pharma)}
                        dense
                      >
                        <S.ListItemIcon>
                          <S.CheckboxListItem
                            edge="start"
                            size="medium"
                            color="primary"
                            disableRipple
                            checked={
                              !!checkedPharmaceuticals.find(
                                (item) => item.id === pharma.id,
                              )
                            }
                          />
                        </S.ListItemIcon>

                        <ListItemText
                          primary={<Body variant="xxs">{pharma.name}</Body>}
                        />
                      </S.ListItem>
                    ))}
                </List>
              </S.ListWrapper>
            </S.SelectPharmasWrapper>
          )}
        </S.ChipContainer>

        <S.FooterWrapper>
          <Button variant="outlined" onClick={handleCloseOffCanvas}>
            Agora não
          </Button>

          <Button
            color="primary"
            onClick={onSubmitSelections}
            disabled={!checkedPharmaceuticals.length}
          >
            Aceitar
          </Button>
        </S.FooterWrapper>
      </S.Content>
    </S.Drawer>
  );
};

export default PharmasOffCanvas;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Body,
  Button,
  MaterialUICore,
  Logo,
  MaterialIcons,
} from '@iclinic/design-system';

import * as S from './index.styles';
import { SIGNUP_PAYWALL_KEY } from 'features/new-auth/stepSignup/constants';
import { gotoAppRoute } from 'shared/utils/appRoutes';
import { readLocalStorage } from 'shared/utils/readFromLocalStorage';
import { getBaseProducts } from 'features/checkout/state/plans/selectors';
import { fetchCheckoutProducts } from 'features/checkout/state/plans';
import { getAnnualDiscount, getPlanDetails } from 'features/checkout/utils';
import { formatToInteger } from 'shared/utils/strings';

const { CheckCircleRounded } = MaterialIcons;

const { Fade } = MaterialUICore;

const { localStorage } = window;

const shouldPaywallShown = () => {
  const paywallData = readLocalStorage(SIGNUP_PAYWALL_KEY, {
    show_paywall: false,
  });

  if (paywallData.show_paywall) {
    paywallData.show_paywall = false;
    localStorage.setItem(
      SIGNUP_PAYWALL_KEY,
      JSON.stringify({
        show_paywall: false,
      }),
    );
    return true;
  }

  return false;
};

const OnboardingPaywall = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [annualDiscount, setAnnualDiscount] = useState<string | null>(null);
  const [annualValue, setAnnualValue] = useState<string | null>(null);
  const [lowerValue, setLowerValue] = useState<string | null>(null);

  const dispatch = useDispatch();
  const products = useSelector(getBaseProducts);

  useEffect(() => {
    if (shouldPaywallShown()) {
      setIsOpen(true);
      window.dataLayer?.push({
        event: 'paywall_pageview',
        page_location: window.location.href,
      });
      dispatch(fetchCheckoutProducts());
    }
  }, [dispatch]);

  const proPlan = products?.find((product) => product.slug === 'pro');
  const starterPlan = products?.find((product) => product.slug === 'starter');

  useEffect(() => {
    if (proPlan) {
      const discount = getAnnualDiscount(proPlan);
      setAnnualDiscount(discount);

      const { valueInteger, valueDecimal } = getPlanDetails(proPlan, true);
      setAnnualValue(`${valueInteger},${valueDecimal}`);
    }

    if (starterPlan) {
      const { valueInteger, valueDecimal } = getPlanDetails(starterPlan, true);
      setLowerValue(`${valueInteger},${valueDecimal}`);
    }
  }, [proPlan, starterPlan]);

  const handleStartTrial = () => {
    setIsOpen(false);
  };

  const handleStartCheckout = () => {
    const checkoutPlan = {
      selected_plan: 'pro',
      recurrence: 'annual',
    };
    localStorage.setItem(SIGNUP_PAYWALL_KEY, JSON.stringify(checkoutPlan));
    gotoAppRoute('/configuracoes/assinatura/');
  };

  if (!isOpen) return null;

  return (
    <S.Modal open>
      <Fade in>
        <S.ModalContainer>
          <S.FlexContainer>
            <S.LogoContainer>
              <Logo width={152} />
            </S.LogoContainer>

            <S.Heading variant="md">
              Assine agora com desconto exclusivo{' '}
              <span>de R${formatToInteger(annualDiscount)} OFF</span> no Plano
              Pro Anual
            </S.Heading>

            <S.CardContainer>
              <S.ImageContainer>
                <img
                  src={`${
                    process.env.ASSETS_PATH as string
                  }images/onBoarding/onboarding-people-text.svg`}
                  alt="Início da atualização da regulamentação do CFM"
                />
              </S.ImageContainer>

              <S.Card>
                <S.CardHeader>
                  <S.CardTitle variant="sm">
                    Plano {proPlan?.name} Anual
                  </S.CardTitle>

                  <S.Chip
                    label={`R$${formatToInteger(annualDiscount)} OFF`}
                    severity="success"
                    variant="default"
                    size="medium"
                  />
                </S.CardHeader>

                <S.PaymentContainer>
                  <S.PriceContainer>
                    <S.Currency variant="xs">R$</S.Currency>
                    <S.Price variant="xxl">{annualValue}</S.Price>
                    <S.Frequency variant="xs">/mês</S.Frequency>
                  </S.PriceContainer>

                  <S.PaymentInfo variant="xxs">
                    Cobrança anual
                    <br />
                    Renovação automática
                  </S.PaymentInfo>

                  <S.PaymentText>
                    <Body variant="xxs">Por profissional de saúde</Body>
                  </S.PaymentText>
                </S.PaymentContainer>

                <S.ListContainer>
                  <S.ListItem>
                    <CheckCircleRounded />
                    Agenda, Prontuário, Prescrição e Financeiro
                  </S.ListItem>
                  <S.ListItem>
                    <CheckCircleRounded />
                    Lembrete de consulta por WhatsApp
                  </S.ListItem>
                  <S.ListItem>
                    <CheckCircleRounded />
                    Agendamentos on-line
                  </S.ListItem>
                </S.ListContainer>

                <S.Divider />

                <S.CardFooter>
                  <Button
                    onClick={handleStartCheckout}
                    data-ga="paywall_checkout_click"
                  >
                    Assine com desconto
                  </Button>
                  <Body variant="xs" bold>
                    Conheça planos a partir de R${lowerValue}
                  </Body>
                </S.CardFooter>
              </S.Card>
            </S.CardContainer>
          </S.FlexContainer>

          <S.Footer>
            <S.FooterText variant="xxs">
              Gostaria de ajuda para conhecer o iClinic?
              <S.FooterLink
                target="_blank"
                href="https://api.whatsapp.com/send?phone=5511975902426&text=Ol%C3%A1,%20Vitor!%20Tenho%20algumas%20d%C3%BAvidas%20sobre%20o%20iClinic.%20Voc%C3%AA%20pode%20me%20ajudar"
                data-ga="paywall_talk_to_sales_click"
              >
                Converse com um especialista
              </S.FooterLink>
            </S.FooterText>

            <S.ButtonContainer>
              <Body variant="xs" bold>
                Ainda não tem certeza?
              </Body>
              <S.SubscribeButton
                onClick={handleStartTrial}
                data-ga="paywall_start_trial_click"
              >
                Iniciar teste gratuito
              </S.SubscribeButton>
            </S.ButtonContainer>
          </S.Footer>
        </S.ModalContainer>
      </Fade>
    </S.Modal>
  );
};

export default OnboardingPaywall;

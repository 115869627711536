import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Pharmaceutical } from 'features/free-samples/services/types';
import { RequestStatus } from 'shared/constants/State';

export interface OptInConfirmationState {
  status?: RequestStatus;
  isModalOpen: boolean;
  isOffCanvasOpen: boolean;
  isSuccessModalOpen: boolean;
  availablePharmas?: {
    status?: RequestStatus;
    pharmas?: Pharmaceutical[];
  };
  selectedPharmas?: Pharmaceutical[];
  intercomEvents?: {
    status?: RequestStatus;
    event?: string;
  };
}

export const initialState: OptInConfirmationState = {
  status: RequestStatus.Pending,
  isModalOpen: false,
  isOffCanvasOpen: false,
  isSuccessModalOpen: false,
};

export const nameStore = '@webapp/opt-in-confirmation';

export const fetchPharmasData = createAction(`${nameStore}/fetchPharmasData`);

export const setOptInConfirmation = createAction(
  `${nameStore}/setOptInConfirmation`,
);

export const sendIntercomEvent = createAction<string>(
  `${nameStore}/sendIntercomEvent`,
);

const OptInConfirmationSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    submitOptInConfirmationSuccess: (state): OptInConfirmationState => ({
      ...state,
      status: RequestStatus.Success,
    }),
    submitOptInConfirmationFailure: (state): OptInConfirmationState => ({
      ...state,
      status: RequestStatus.Error,
    }),
    openConfirmationModal: (state): OptInConfirmationState => ({
      ...state,
      isModalOpen: true,
    }),
    closeConfirmationModal: (state): OptInConfirmationState => ({
      ...state,
      isModalOpen: false,
    }),
    openOptinOffCanvas: (state): OptInConfirmationState => ({
      ...state,
      isOffCanvasOpen: true,
      isModalOpen: false,
    }),
    closeOptinOffCanvas: (state): OptInConfirmationState => ({
      ...state,
      isOffCanvasOpen: false,
    }),
    fetchPharmasStart: (state): OptInConfirmationState => ({
      ...state,
      availablePharmas: {
        ...state.availablePharmas,
        status: RequestStatus.Pending,
      },
    }),
    fetchPharmasSuccess: (
      state,
      action: PayloadAction<Pharmaceutical[]>,
    ): OptInConfirmationState => ({
      ...state,
      availablePharmas: {
        status: RequestStatus.Success,
        pharmas: action.payload,
      },
    }),
    fetchPharmasFailure: (state): OptInConfirmationState => ({
      ...state,
      availablePharmas: {
        ...state.availablePharmas,
        status: RequestStatus.Error,
      },
    }),
    setSelectedPharmas: (state, action: PayloadAction<Pharmaceutical[]>) => ({
      ...state,
      selectedPharmas: action.payload,
      isOffCanvasOpen: false,
      isSuccessModalOpen: true,
      status: RequestStatus.Success,
    }),
    openSuccessModal: (state): OptInConfirmationState => ({
      ...state,
      isSuccessModalOpen: true,
    }),
    closeSuccessModal: (state): OptInConfirmationState => ({
      ...state,
      isSuccessModalOpen: false,
    }),
    sendIntercomEventSuccess: (state, action: PayloadAction<string>) => ({
      ...state,
      intercomEvents: {
        ...state.intercomEvents,
        status: RequestStatus.Success,
        event: action.payload,
      },
    }),
    sendIntercomEventFailure: (state): OptInConfirmationState => ({
      ...state,
      intercomEvents: {
        ...state.intercomEvents,
        status: RequestStatus.Error,
      },
    }),
  },
});

export const {
  submitOptInConfirmationSuccess,
  submitOptInConfirmationFailure,
  openConfirmationModal,
  closeConfirmationModal,
  openOptinOffCanvas,
  closeOptinOffCanvas,
  fetchPharmasStart,
  fetchPharmasSuccess,
  fetchPharmasFailure,
  setSelectedPharmas,
  openSuccessModal,
  closeSuccessModal,
  sendIntercomEventSuccess,
  sendIntercomEventFailure,
} = OptInConfirmationSlice.actions;

export default OptInConfirmationSlice.reducer;

import React, { createContext, ReactNode, useContext, useMemo } from 'react';

export interface VariantContextProps {
  isVariant: boolean;
}

export const VariantContext = createContext<VariantContextProps>({
  isVariant: false,
});

interface VariantProviderProps {
  isVariant: boolean;
  children: ReactNode;
}

export const VariantProvider: React.FC<VariantProviderProps> = ({
  isVariant,
  children,
}) => {
  const VariantContextValue = useMemo(() => ({ isVariant }), [isVariant]);

  return (
    <VariantContext.Provider value={VariantContextValue}>
      {children}
    </VariantContext.Provider>
  );
};

export const useVariantContext = (): VariantContextProps =>
  useContext(VariantContext);

import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Heading, Body, Button } from '@iclinic/design-system';

import useStyles from './dialogStyles.style';
import { image } from 'shared/utils/urlGetter';
import { nextStep } from '../state';
import { UpdateStepProps } from '../types';

const StartStep = ({ onClose }: UpdateStepProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleNext = () => {
    dispatch(nextStep());
  };

  return (
    <>
      <Box alignItems="center" display="flex" flexDirection="column" mb={3}>
        <img
          src={image('cfmUpdate.start')}
          alt="Início da atualização da regulamentação do CFM"
        />
        <Box mb={1}>
          <Heading variant="md">Atualização cadastral</Heading>
        </Box>

        <Body variant="sm" align="center">
          A <strong>resolução nº 2.299/2021</strong> do <strong>CFM</strong>{' '}
          tornou alguns dados obrigatórios. Por favor, preencha suas informações
          para usar o iClinic.
        </Body>
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Button
          color="transparent"
          className={classes.exitButton}
          onClick={onClose}
        >
          Sair
        </Button>
        <Button onClick={handleNext}>Atualizar</Button>
      </Box>
    </>
  );
};

export default StartStep;

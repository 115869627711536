import React, { useState } from 'react';
import { scroller } from 'react-scroll';
import { useFormikContext } from 'formik';
import { MaterialIcons, MaterialUICore } from '@iclinic/design-system';

import { SellerType } from 'features/onlinePayment/containers/config/seller/constants';
import { SellerForm } from 'features/onlinePayment/state/config/types';
import useStyles from './SellerProfileMenu.styles';

const { List, ListItem, ListItemIcon, ListItemText } = MaterialUICore;
const { Person, PersonPinRounded, Room } = MaterialIcons;

const SellerProfileMenu = () => {
  const [selected, setSelected] = useState('');
  const classes = useStyles();

  const handleLinkClick = (link: string) => {
    setSelected(link);
    scroller.scrollTo(link, {
      duration: 500,
      smooth: true,
      offset: -50,
    });
  };

  const { values } = useFormikContext<SellerForm>();

  return (
    <div className={classes.container}>
      <List className={classes.menu}>
        <ListItem
          selected={selected === 'card-profile'}
          className={classes.menuItem}
          onClick={() => handleLinkClick('card-profile')}
        >
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText primary="Perfil" />
        </ListItem>
        <ListItem
          selected={selected === 'card-address'}
          className={classes.menuItem}
          onClick={() => handleLinkClick('card-address')}
        >
          <ListItemIcon>
            <Room />
          </ListItemIcon>
          <ListItemText primary="Endereço" />
        </ListItem>
        {values.type === SellerType.PJ && (
          <ListItem
            selected={selected === 'card-clinic-owner'}
            className={classes.menuItem}
            onClick={() => handleLinkClick('card-clinic-owner')}
          >
            <ListItemIcon>
              <PersonPinRounded />
            </ListItemIcon>
            <ListItemText primary="Dono da clínica" />
          </ListItem>
        )}
      </List>
    </div>
  );
};

export default SellerProfileMenu;

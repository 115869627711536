import React from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@iclinic/design-system';

import PasswordField from '../PasswordField';
import * as S from './styles';

interface FeedbackProps {
  customFeedback: boolean;
  renderFeedback?: (
    eightCharsIcon: React.ReactNode,
    atLeastOneUpperCharIcon: React.ReactNode,
    atLeastOneLowerCharIcon: React.ReactNode,
    atLeastOneNumberIcon: React.ReactNode,
    atLeastOneSpecialCharIcon: React.ReactNode,
  ) => React.ReactNode;
  requirements: {
    eightChars: boolean;
    atLeastOneUpperChar: boolean;
    atLeastOneLowerChar: boolean;
    atLeastOneNumber: boolean;
    atLeastOneSpecialChar: boolean;
  };
}

const PasswordFeedback = ({
  customFeedback,
  renderFeedback,
  requirements,
}: FeedbackProps): JSX.Element | null => {
  const {
    eightChars,
    atLeastOneUpperChar,
    atLeastOneLowerChar,
    atLeastOneNumber,
    atLeastOneSpecialChar,
  } = requirements;

  const successIcon = (testId: string) => (
    <S.SuccessIcon data-testid={testId} />
  );
  const errorIcon = (testId: string) => <S.ErrorIcon data-testid={testId} />;

  const eightCharsIcon = eightChars
    ? successIcon('eightChars-check')
    : errorIcon('eightChars-fail');
  const atLeastOneUpperCharIcon = atLeastOneUpperChar
    ? successIcon('atLeastOneUpperChar-check')
    : errorIcon('atLeastOneUpperChar-fail');
  const atLeastOneLowerCharIcon = atLeastOneLowerChar
    ? successIcon('atLeastOneLowerChar-check')
    : errorIcon('atLeastOneLowerChar-fail');
  const atLeastOneNumberIcon = atLeastOneNumber
    ? successIcon('atLeastOneNumber-check')
    : errorIcon('atLeastOneNumber-fail');
  const atLeastOneSpecialCharIcon = atLeastOneSpecialChar
    ? successIcon('atLeastOneSpecialChar-check')
    : errorIcon('atLeastOneSpecialChar-fail');

  const customFeedbackComponent = (): JSX.Element | null =>
    renderFeedback ? (
      <div>
        {renderFeedback(
          eightCharsIcon,
          atLeastOneUpperCharIcon,
          atLeastOneLowerCharIcon,
          atLeastOneNumberIcon,
          atLeastOneSpecialCharIcon,
        )}
      </div>
    ) : null;

  return customFeedback ? (
    customFeedbackComponent()
  ) : (
    <S.PasswordFeedbackContainer>
      <S.PasswordCheckLine>
        {eightCharsIcon}
        <S.Body variant="xs">8 caracteres no mínimo</S.Body>
      </S.PasswordCheckLine>

      <S.PasswordCheckLine>
        {atLeastOneUpperCharIcon}
        <S.Body variant="xs">Pelo menos uma letra maiúscula</S.Body>
      </S.PasswordCheckLine>

      <S.PasswordCheckLine>
        {atLeastOneLowerCharIcon}
        <S.Body variant="xs">Pelo menos uma letra minúscula</S.Body>
      </S.PasswordCheckLine>

      <S.PasswordCheckLine>
        {atLeastOneNumberIcon}
        <S.Body variant="xs">Pelo menos 1 número</S.Body>
      </S.PasswordCheckLine>

      <S.PasswordCheckLine>
        {atLeastOneSpecialCharIcon}
        <S.Body variant="xs">Pelo menos um caractere especial</S.Body>
      </S.PasswordCheckLine>
    </S.PasswordFeedbackContainer>
  );
};

interface Props {
  passwordLabel?: string;
  passwordPlaceHolder?: string;
  passwordConfirmation?: boolean;
  requirements: {
    eightChars: boolean;
    atLeastOneUpperChar: boolean;
    atLeastOneLowerChar: boolean;
    atLeastOneNumber: boolean;
    atLeastOneSpecialChar: boolean;
  };
  disabled?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onVisibilityChange?: (visible: boolean) => void;
  customFeedback?: boolean;
  renderFeedback?: (
    eightCharsIcon: React.ReactNode,
    atLeastOneUpperCharIcon: React.ReactNode,
    atLeastOneLowerCharIcon: React.ReactNode,
    atLeastOneNumberIcon: React.ReactNode,
    atLeastOneSpecialCharIcon: React.ReactNode,
  ) => React.ReactNode;
}

export default ({
  passwordLabel,
  passwordPlaceHolder,
  passwordConfirmation = false,
  requirements,
  disabled = false,
  onBlur,
  onFocus,
  onVisibilityChange,
  customFeedback = false,
  renderFeedback,
}: Props) => {
  const { errors, touched, handleBlur, handleChange, values } =
    useFormikContext<any>();

  const passHelperText =
    touched.password && errors.password ? errors.password : null;
  const confimationHelperText =
    touched.password && errors.confirmPassword ? errors.confirmPassword : null;

  const handlePasswordBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    handleBlur(event);

    if (onBlur) onBlur(event);
  };

  const handlePasswordFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(event);
    }
  };

  return (
    <>
      <Box mb={1}>
        <PasswordField
          autoComplete="off"
          fullWidth
          label={passwordLabel || 'Cadastre sua senha'}
          placeholder={passwordPlaceHolder || ''}
          id="password"
          name="password"
          error={!!(errors.password && touched.password)}
          helperText={passHelperText}
          onChange={handleChange}
          onBlur={handlePasswordBlur}
          value={values.password}
          data-ga="setup-password"
          disabled={disabled}
          onVisibilityChange={onVisibilityChange}
          onFocus={handlePasswordFocus}
        />
      </Box>

      {passwordConfirmation && (
        <PasswordField
          autoComplete="off"
          fullWidth
          label="Confirme sua senha"
          id="confirmPassword"
          name="confirmPassword"
          placeholder="Repita sua senha"
          error={!!(errors.confirmPassword && touched.confirmPassword)}
          helperText={confimationHelperText}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.confirmPassword}
          data-ga="setup-confirmPassword"
          disabled={disabled}
        />
      )}
      <PasswordFeedback
        customFeedback={customFeedback}
        renderFeedback={renderFeedback}
        requirements={requirements}
      />
    </>
  );
};

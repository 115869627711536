import {
  Body as DSBody,
  axiomTokens,
  Button,
  MaterialUICore,
  Box,
  Heading as DSHeading,
  Chip as DSChip,
} from '@iclinic/design-system';

const { styled, Modal: ModalDS } = MaterialUICore;

const { font, border, color, spacing, shadowBrand } = axiomTokens;

const boxSizingValue = 'border-box';
const justifyContentValue = 'space-between';
const colorButton = {
  0: '#003F72',
  1: '#D9EBFC',
};
const colorGradient = {
  0: '#FFFFFF',
  1: '#D6E9FF',
};
export const Modal = styled(ModalDS)({});

export const ModalContainer = styled(Box)(({ theme }) => ({
  overflowX: 'auto',
  height: '100%',
  background: `linear-gradient(180deg, ${colorGradient[0]} 74.54%, ${colorGradient[1]} 100%)`,
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    display: 'block',
    background: `linear-gradient(180deg, ${colorGradient[0]} 64.54%, ${colorGradient[1]} 100%)`,
  },
}));

export const FlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  maxWidth: 1040,

  [theme.breakpoints.down('md')]: {
    padding: spacing[6],
  },
}));

export const LogoContainer = styled(Box)({
  padding: 0,
  margin: `0 0 ${spacing[10]}`,
});

export const Heading = styled(DSHeading)({
  padding: 0,
  margin: `0 0 ${spacing[6]}`,
  fontSize: font.size.sm,
  textAlign: 'center',
  '& span': {
    color: color.highlight.blue[2],
  },
});

export const CardContainer = styled('div')(({ theme }) => ({
  padding: `${spacing[8]} 0`,
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  boxSizing: boxSizingValue,
  gap: spacing[10],
  justifyContent: justifyContentValue,
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: spacing[8],
    marginBottom: spacing[24],
  },

  [theme.breakpoints.down('sm')]: {
    marginBottom: spacing[32],
    paddingTop: 0,
  },

  [theme.breakpoints.down('xs')]: {
    marginBottom: spacing[40],
  },
}));

export const ImageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}));

export const Card = styled('div')(({ theme }) => ({
  padding: spacing[4],
  backgroundColor: color.background[1],
  display: 'flex',
  flexDirection: 'column',
  borderRadius: border.radius.sm,
  border: `${border.width.xs} solid ${color.border.activated}`,
  boxShadow: shadowBrand.level[3],
  width: '100%',
  boxSizing: boxSizingValue,
  position: 'relative',
  maxWidth: 424,

  [theme.breakpoints.down('sm')]: {
    padding: `${spacing[2]} ${spacing[2]}`,
    borderRadius: border.radius.sm,
  },
}));

export const CardHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: justifyContentValue,
  width: '100%',
  boxSizing: boxSizingValue,
  marginBottom: spacing[4],
});

export const CardTitle = styled(DSHeading)({
  fontSize: font.size.sm,
  letterSpacing: font.letterSpacing.sm,
});

export const Chip = styled(DSChip)({
  backgroundColor: color.brand.highlight.teal[3],
  marginLeft: 'auto',
});

export const PaymentContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  columnGap: spacing[4],
  gridTemplateAreas: `
    "price-container payment-info"
    "payment-text payment-text"
  `,
  gridTemplateColumns: '1fr 1fr',

  [theme.breakpoints.down('sm')]: {
    gridTemplateAreas: `
      "price-container payment-text"
      "payment-info payment-info"
    `,
  },
}));

export const PriceContainer = styled('div')({
  display: 'flex',
  gap: spacing[1],
  marginTop: spacing[2],
  marginBottom: spacing[2],
  gridArea: 'price-container',
});

export const Currency = styled(DSHeading)({
  alignSelf: 'center',
  fontSize: font.size.xxs,
});

export const Price = styled(DSHeading)({
  alignSelf: 'center',
  color: color.highlight.blue[2],
  fontSize: font.size.xxl,
});

export const Frequency = styled(DSHeading)({
  alignSelf: 'flex-end',
  fontSize: font.size.xxs,
});

export const PaymentInfo = styled(DSBody)({
  color: color.typeface.smooth,
  fontSize: font.size.xxs,
  gridArea: 'payment-info',
});

export const PaymentText = styled('div')({
  marginTop: 0,
  color: color.typeface.smooth,
  marginBottom: spacing[3],
  gridArea: 'payment-text',
});

export const ListContainer = styled('ul')({
  margin: `${spacing[4]} 0`,
  display: 'flex',
  flexDirection: 'column',
  gap: spacing[2],
});

export const ListItem = styled('li')({
  display: 'flex',
  gap: spacing['2'],
  alignItems: 'center',
  color: color.typeface.pure,
  fontWeight: font.weight.bold,

  '& .MuiSvgIcon-root': {
    color: color.interactive.accent.default,
  },
});

export const Divider = styled('hr')({
  backgroundColor: color.others.divider,
  margin: `${spacing[4]} 0`,
});

export const CardFooter = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: spacing[5],
  padding: `${spacing[4]} 0 ${spacing[2]}`,
});

export const Footer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  background: color.background[1],
  padding: spacing[6],
  display: 'flex',
  alignItems: 'center',
  justifyContent: justifyContentValue,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    gap: spacing[4],
    alignItems: 'center',
  },
}));

export const FooterText = styled(DSBody)(({ theme }) => ({
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('xs')]: {
    textAlign: 'center',
  },
}));

export const FooterLink = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  fontSize: font.size.xxs,
  color: color.link.default,
  margin: spacing[1],

  [theme.breakpoints.down('xs')]: {
    display: 'block',
  },
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
  gap: spacing[3],

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },

  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const SubscribeButton = styled(Button)({
  color: colorButton[0],
  background: colorButton[1],
  '&:hover': {
    color: colorButton[1],
    background: colorButton[0],
  },
});

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Logo, MaterialIcons, tokens } from '@iclinic/design-system';

import history from 'routes/history';
import { triggerSubmitAutoLogin } from '../../state/reducer';
import { autoLoginSelector, emailSelector } from '../../state/selectors';
import * as S from './Success.styles';

const { ShieldIcon, MarkEmailReadRounded } = MaterialIcons;

const SignupSuccess = () => {
  const dispatch = useDispatch();
  const hasAutoLogin = useSelector(autoLoginSelector);
  const email = useSelector(emailSelector);

  useEffect(() => {
    if (!hasAutoLogin) history.push('/new/cadastro/');
  }, [hasAutoLogin]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(triggerSubmitAutoLogin());
    }, 3000);
    return () => clearTimeout(timer);
  });

  return (
    <S.SuccessPageContainer>
      <Box
        mx="auto"
        textAlign="center"
        px={{ xs: tokens.spacing.sm, md: tokens.spacing.md }}
      >
        <S.Box>
          <Logo width={152} />
        </S.Box>

        <S.Heading variant="lg">Seu consultório na nuvem está pronto</S.Heading>

        <S.CardInfoContainer>
          <S.InfoCard>
            <MarkEmailReadRounded /> <br />
            <S.Body variant="xs">
              Seu e-mail de acesso é <br />
              <S.Strong>{email}</S.Strong>
            </S.Body>
          </S.InfoCard>

          <S.InfoCard>
            <ShieldIcon /> <br />
            <S.Body variant="xs">
              Sua senha é considerada <br /> <S.Strong>Segura</S.Strong>
            </S.Body>
          </S.InfoCard>

          <S.InfoCard>
            <S.CheckCircle /> <br />
            <S.Body variant="xs">
              Sua conta foi criada <br />
              <S.Strong>Grátis por 5 dias </S.Strong>
            </S.Body>
          </S.InfoCard>
        </S.CardInfoContainer>
      </Box>
    </S.SuccessPageContainer>
  );
};
export default SignupSuccess;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Heading, MaterialIcons, MaterialUICore } from '@iclinic/design-system';

import { Product } from '../../services/types';
import { getAnnualDiscount, getPlanDetails, splitValue } from '../../utils';
import {
  toggleOffCanvasOpenClose,
  updateSelectedPlan,
  updateSelectedAddons,
  setIsAnnual,
} from '../../state/plans';
import {
  getSelectedPlan,
  getSelectedAddons,
  getAddons,
} from 'features/checkout/state/plans/selectors';
import Addon from './Addon';
import PlanValues from './PlanValues';
import * as S from './PlanSelect.styles';
import { SIGNUP_PAYWALL_KEY } from 'features/new-auth/stepSignup/constants';
import { readLocalStorage } from 'shared/utils/readFromLocalStorage';

const { ChevronRightRounded } = MaterialIcons;

export type PlanProps = {
  isAnnual: boolean;
  mostSeller?: string;
  plan: Product;
};

const { Radio } = MaterialUICore;

export default function PlanSelect({
  isAnnual,
  mostSeller = '',
  plan,
}: PlanProps): JSX.Element {
  const {
    period,
    valueInteger,
    valueDecimal,
    discountValueInteger,
    discountValueDecimal,
    percentageValue,
    frequencyNumber,
    dateExpiration,
  } = getPlanDetails(plan, isAnnual);
  const { id, name, slug } = plan;
  const isMostSeller = mostSeller === plan.slug;
  const annualDiscount = getAnnualDiscount(plan);
  const showChips = !!(isAnnual || discountValueInteger);
  const dispatch = useDispatch();

  const addons = useSelector(getAddons);
  const selectedAddons = useSelector(getSelectedAddons);
  const selectedPlan: Product | undefined = useSelector(getSelectedPlan);
  const checked = selectedPlan ? selectedPlan.slug === plan.slug : false;

  const onClickPlanHandler = () => {
    if (!checked) {
      dispatch(updateSelectedPlan(plan));
      dispatch(updateSelectedAddons([]));
    }
  };

  const handleClick = () => {
    dispatch(toggleOffCanvasOpenClose());
  };

  useEffect(() => {
    const paywallData = readLocalStorage(SIGNUP_PAYWALL_KEY, {
      selected_plan: '',
      recurrence: '',
    });

    if (paywallData) {
      if (paywallData.selected_plan === plan.slug) {
        dispatch(updateSelectedPlan(plan));
      }
      const recurrenceIsAnnual = paywallData.recurrence === 'annual';
      dispatch(setIsAnnual(recurrenceIsAnnual));
    }
  }, [dispatch, plan]);

  return (
    <S.PlansContainer onClick={onClickPlanHandler} active={checked}>
      <S.PlanContentDescription showChips={showChips}>
        <S.TitleWrapper>
          <Heading variant="sm">{name}</Heading>
          {isMostSeller && (
            <S.MostSeller variant="xxs">Mais vendido</S.MostSeller>
          )}
        </S.TitleWrapper>

        <S.RadioWrapper>
          <Radio
            color="primary"
            name="plan-option"
            value={id}
            inputProps={{ 'aria-label': name }}
            checked={checked}
          />
        </S.RadioWrapper>

        <PlanValues
          slug={slug}
          decimal={valueDecimal}
          value={valueInteger}
          discountValueInteger={discountValueInteger}
          discountValueDecimal={discountValueDecimal}
          period={period}
        />

        {showChips && (
          <S.ChipsWrapper>
            {discountValueInteger && (
              <S.Chip
                size="small"
                variant="outlined"
                severity="success"
                active
                label={`Cupom de ${percentageValue || ''}%`}
              />
            )}

            {isAnnual && (
              <>
                <S.Chip
                  size="small"
                  variant="outlined"
                  label="Pago Anualmente"
                />

                <S.Chip
                  data-testid={`${slug}-annual-discount`}
                  size="small"
                  variant="outlined"
                  label={
                    <>
                      Você economiza{' '}
                      <strong>R$ {splitValue(annualDiscount).integer}</strong>{' '}
                      por ano
                    </>
                  }
                />
              </>
            )}

            {discountValueInteger && (
              <S.ValidityDiscount variant="xxs">
                *Desconto válido por {frequencyNumber} {dateExpiration}
              </S.ValidityDiscount>
            )}
          </S.ChipsWrapper>
        )}
      </S.PlanContentDescription>

      <S.AddonsCollapseWrapper in={checked}>
        {addons && (
          <>
            <S.AddonTitle variant="xs">
              Adicione mais recursos à sua assinatura:{' '}
            </S.AddonTitle>
            {addons.map((addon) => (
              <Addon
                key={addon.id}
                addon={addon}
                isAnnual={isAnnual}
                plan={slug}
                checked={
                  selectedAddons &&
                  selectedAddons.map((item) => item.id).includes(addon.id)
                }
              />
            ))}
          </>
        )}
      </S.AddonsCollapseWrapper>

      {checked && (
        <S.ButtonPlanDetails
          endIcon={<ChevronRightRounded fontSize="small" />}
          variant="text"
          color="transparent"
          onClick={handleClick}
        >
          Ver detalhes do plano
        </S.ButtonPlanDetails>
      )}
    </S.PlansContainer>
  );
}

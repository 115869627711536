import {
  axiomTokens,
  MaterialUICore,
  Body as DSBody,
  Box,
  MaterialIcons,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { font, color, spacing } = axiomTokens;

const { ErrorRounded, CheckCircleRounded } = MaterialIcons;

const iconSize = 13.33;
const icon = { width: iconSize, height: iconSize, marginRight: 5.33 };

export const PasswordFeedbackContainer = styled(Box)({
  margin: `${spacing[5]} 0`,
  display: 'flex',
  flexDirection: 'column',
  gap: spacing['2'],
});

export const PasswordCheckLine = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const Body = styled(DSBody)({
  fontSize: font.size.xs,
  color: color.typeface.smooth,
});

export const SuccessIcon = styled(CheckCircleRounded)({
  color: color.typeface.feedback.success,
  ...icon,
});

export const ErrorIcon = styled(ErrorRounded)({
  color: color.typeface.feedback.alert,
  ...icon,
});

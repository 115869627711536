import { call, put, takeLatest, select, retry, all } from 'redux-saga/effects';
import { Response } from '@types';

import {
  getEmailSettings,
  saveEmailSettings,
  updateSellerRequest,
} from 'features/onlinePayment/services/config';
import {
  SellerRawData,
  EmailsSettingsRawData,
  SellerBankAccountRawData,
  Address,
} from 'features/onlinePayment/services/config/types';
import { captureException } from 'shared/utils/handlerErrors';
import {
  fetchEmailsSettingsSuccess,
  fetchEmailsSettingsFailure,
  saveEmailsSettings,
  saveEmailSettingsSuccess,
  saveEmailSettingsFailure,
  initSettings,
  saveSellerEditing,
  updateAddressSuccess,
  updateAddressFailure,
} from '.';
import {
  normalizeSellerEmailsSettingsDataResponseData,
  normalizeSellerEmailsSettingsDataRequestBody,
} from './normalizers';
import { getSeller } from '../register/selectors';
import { SellerEditForm } from '../types';
import {
  saveInstallmentsWorker,
  updateSellerBankAccountWorker,
} from '../bankAccount/sagas';
import { getSellerBankAccount } from '../bankAccount/selectors';
import { fetchBankAccount, fetchBanks } from '../bankAccount';
import { bankAccountHasChanged } from './utils';

type SaveEmailsSettingsAction = ReturnType<typeof saveEmailsSettings>;
type InitSettingsAction = ReturnType<typeof initSettings>;
type SaveSellerEditingAction = ReturnType<typeof saveSellerEditing>;

export function* fetchEmailsSettingsWorker(seller: SellerRawData) {
  try {
    const sellerId = seller?.id;
    const retry_times = 3;
    const retry_delay_ms = 0;

    const { data }: Response<EmailsSettingsRawData> = yield retry(
      retry_times,
      retry_delay_ms,
      getEmailSettings,
      sellerId,
    );

    const normalizeSellerEmailConfig =
      normalizeSellerEmailsSettingsDataResponseData(data);

    yield put(fetchEmailsSettingsSuccess(normalizeSellerEmailConfig));
  } catch (error) {
    yield put(fetchEmailsSettingsFailure());
    captureException(error);
  }
}

export function* saveEmailsSettingsWorker({
  payload: sellerEmailConfig,
}: SaveEmailsSettingsAction) {
  try {
    const seller: SellerRawData = yield select(getSeller);
    const sellerId = seller?.id;
    const normalizeForm =
      normalizeSellerEmailsSettingsDataRequestBody(sellerEmailConfig);
    yield call(saveEmailSettings, sellerId, normalizeForm);
    yield put(saveEmailSettingsSuccess());
  } catch (error) {
    yield put(saveEmailSettingsFailure());
    captureException(error);
  }
}

export function* saveSellerBankAccountWorker(params: SellerEditForm) {
  const data: SellerBankAccountRawData | undefined = yield select(
    getSellerBankAccount,
  );

  if (!data || bankAccountHasChanged(data, params))
    yield call(updateSellerBankAccountWorker, params);
}

export function* saveSellerAddressWorker(params: SellerEditForm) {
  try {
    const {
      city,
      neighborhood,
      number,
      state,
      street,
      zipCode: zip_code,
      complement,
    } = params;

    const { data }: Response<Address> = yield call(
      updateSellerRequest,
      params,
      {
        city,
        neighborhood,
        number,
        state,
        street,
        zip_code,
        complement,
      },
    );
    yield put(updateAddressSuccess(data));
    return data;
  } catch (error) {
    yield put(updateAddressFailure());
    captureException(error);
    return null;
  }
}

export function* saveSellerEditingWorker(params: SaveSellerEditingAction) {
  yield all([
    call(saveSellerBankAccountWorker, params.payload),
    call(saveSellerAddressWorker, params.payload),
    call(saveInstallmentsWorker, params.payload),
    // put(saveDocuments({ sellerDocuments, sellerId: seller.id })),
    put(saveEmailsSettings(params.payload)),
  ]);
}

export function* initSettingsWorker({ payload: seller }: InitSettingsAction) {
  yield all([
    put(fetchBanks()),
    put(fetchBankAccount(seller.bank_account?.id)),
    call(fetchEmailsSettingsWorker, seller),
  ]);
}

export default function* watchSaveWorker() {
  yield takeLatest(initSettings, initSettingsWorker);
  yield takeLatest(saveEmailsSettings, saveEmailsSettingsWorker);
  yield takeLatest(saveSellerEditing, saveSellerEditingWorker);
}

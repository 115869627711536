import { call, put, takeLatest, all, fork, select } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';

import iclinic from 'services/iclinic';
import filterTerms from 'state/userInfo/utils';
import { handlerErrors } from 'state/shared/sagas';
import { getNextURLParam, getNextUrl } from 'shared/utils/url';
import {
  AUTH_TOKEN_KEY,
  PASSWORD_EXPIRATION_FLOW_KEY,
  USER_ID_KEY,
} from 'shared/constants/auth';
import { SERVICE_TERMS } from 'state/userInfo/constants';
import { dispatchGaEvent } from 'shared/utils/googleAnalytics';
import { dispatchHubspotEvent } from 'shared/utils/hubspot';
import * as types from './constants';
import {
  LoginResponseData,
  LoginResponseForm,
  LoginSocialResponseData,
  LoginSocialResponseForm,
  ServiceTerms,
} from './types';
import {
  authorize,
  unauthorize,
  removeToken,
  loginWithEmailFailure,
  loginWithGoogleAuth2Failure,
  loginWithEmailSubmit,
  authenticateFailure,
  logoutManual,
  setTokenManual,
} from './actions';
import { getUtmsData } from '../stepSignup/utils';
import { captureException } from 'shared/utils/handlerErrors';
import { getAuthToken } from './selectors';

export const errorMessageDictionary = {
  'password-expired': 'Sua senha foi expirada',
  'invalid-user-password': 'Usuário ou senha inválidos',
} as const;

type ErrorMessageDictionaryKeys = keyof typeof errorMessageDictionary;
type ErrorMessageDictionaryValues =
  typeof errorMessageDictionary[ErrorMessageDictionaryKeys];

type ErrorsResponse = {
  errors: {
    code: ErrorMessageDictionaryKeys;
    message: ErrorMessageDictionaryValues;
  }[];
};

const redirectToPath = (url: string) => window.location.replace(url);

export const GENERIC_ERROR = 'Ocorreu um erro inesperado. Tente novamente.';

type AxiosResponseWithErrors = AxiosError<ErrorsResponse>;

const hasResponseDataErrors = (
  data: AxiosError | unknown,
): data is AxiosResponseWithErrors =>
  !!(data as AxiosResponseWithErrors)?.response?.data?.errors;

const getErrorCode = (
  data: AxiosResponseWithErrors,
): ErrorMessageDictionaryKeys | undefined =>
  data?.response?.data?.errors?.[0]?.code;

export function* workerUnauthorize(action: ReturnType<typeof logoutManual>) {
  yield put(unauthorize());
  yield put(removeToken());
  localStorage.removeItem(AUTH_TOKEN_KEY);
  let nextURL = process.env.UNAUTHORIZE_URL!;

  if (action && action.type !== types.LOGOUT_MANUAL) {
    nextURL += getNextUrl(window.location);
  }

  window.location.href = nextURL;
}

export function* workerSetTokenManual({
  payload,
}: ReturnType<typeof setTokenManual>) {
  const { token } = payload;
  localStorage.setItem(AUTH_TOKEN_KEY, token);

  yield put(authorize());
}

export function* passwordExpiredFlow(responseData: LoginResponseForm) {
  try {
    if (!responseData.password_expired) {
      return;
    }
    const {
      password_expired: passwordExpired,
      days_to_reset: daysToReset,
      expiration_date: expirationDate,
    } = responseData;

    localStorage.setItem(
      PASSWORD_EXPIRATION_FLOW_KEY,
      JSON.stringify({
        passwordExpired,
        daysToReset,
        expirationDate,
      }),
    );
  } catch (error) {
    yield call(captureException, error);
  }
}

export function* clearPasswordExpiredFlow() {
  try {
    localStorage.removeItem(PASSWORD_EXPIRATION_FLOW_KEY);
  } catch (error) {
    yield call(captureException, error);
  }
}

export function* userFlow(
  id: string,
  serviceTerms: ServiceTerms[],
  email?: string,
) {
  localStorage.setItem(USER_ID_KEY, id);
  localStorage.setItem(
    SERVICE_TERMS,
    JSON.stringify(filterTerms(serviceTerms)),
  );
  yield call(dispatchHubspotEvent, 'pe5057975_login_test', {
    email: email || '',
    ...getUtmsData(),
  });
  yield call(dispatchGaEvent, 'on_login', getUtmsData());
}

export function* workerAuthenticate(response: LoginResponseData) {
  try {
    const { data: successfulLoginResponseData } = response;
    const {
      user,
      redirect_url: redirectUrl,
      password_expired: passwordExpired,
    } = successfulLoginResponseData;

    yield call(clearPasswordExpiredFlow);
    yield put(authorize());

    if (user) {
      const { id, service_terms: serviceTerms, email } = user;
      yield call(userFlow, id, serviceTerms, email);
    }

    if (passwordExpired) {
      yield call(passwordExpiredFlow, successfulLoginResponseData);
    }

    redirectToPath(redirectUrl);
  } catch (error) {
    yield call(handlerErrors, error, authenticateFailure);
  }
}

function* loginWithEmailErrorResponseHandler(error: AxiosResponseWithErrors) {
  const errorCode = getErrorCode(error)!;
  const errorMessage = errorMessageDictionary[errorCode] || GENERIC_ERROR;

  if (errorCode === 'password-expired') {
    window.location.replace('/new/usuarios/senha-expirada');
    return;
  }

  yield put(
    loginWithEmailFailure([{ message: errorMessage, code: errorCode }]),
  );
}

type LoginWithEmailSubmitPayload = ReturnType<typeof loginWithEmailSubmit>;
export function* workerLoginWithEmailSubmit({
  payload: { email, password },
}: LoginWithEmailSubmitPayload) {
  try {
    const nextUrl = getNextURLParam(window.location.search);
    const authResponse: AxiosResponse<LoginResponseForm> = yield call(
      iclinic.auth.loginWithEmail,
      email,
      password,
      nextUrl,
    );

    yield call(workerAuthenticate, authResponse);
  } catch (response) {
    if (hasResponseDataErrors(response)) {
      yield call(loginWithEmailErrorResponseHandler, response);
      return;
    }

    yield put(
      loginWithEmailFailure([
        {
          code: '500',
          message:
            'Ocorreu um erro ao acessar sua conta, tente novamente. Caso o erro persista entre em contato com o nosso suporte.',
        },
      ]),
    );
  }
}

/**
 * Authentication with Google Token
 */

export function* workerAuthenticateGoogle(response: LoginSocialResponseData) {
  // post authentication-flow using Google Provider id-token
  try {
    const { data: successfulLoginResponseData } = response;
    const { user, auth_token, uid } = successfulLoginResponseData;

    yield put(authorize());

    if (user) {
      const { id, service_terms: serviceTerms, email } = user;
      yield call(userFlow, id, serviceTerms, email);
    }

    const nextURL = getNextURLParam(window.location.search);
    let redirectURL = `/usuarios/auto-login/?auth_token=${auth_token}&uid=${uid}`;

    if (nextURL) {
      redirectURL += `&next=${nextURL}`;
    }

    redirectToPath(redirectURL);
  } catch (error) {
    yield call(handlerErrors, error, authenticateFailure);
  }
}

export function* workerLoginWithGoogleRequest() {
  try {
    const token: string = yield select(getAuthToken);
    const authResponse: AxiosResponse<LoginSocialResponseForm> = yield call(
      iclinic.auth.loginWithGoogle as any,
      token,
    );
    yield call(workerAuthenticateGoogle, authResponse);
  } catch (error) {
    yield put(
      loginWithGoogleAuth2Failure({
        _error: 'Dados inválidos ao entrar com o Google',
      }),
    );
  }
}

export function* watchAuthFlow() {
  yield takeLatest(types.LOGIN_WITH_EMAIL_SUBMIT, workerLoginWithEmailSubmit);
  yield takeLatest(
    types.LOGIN_WITH_GOOGLE_AUTH2_REQUEST,
    workerLoginWithGoogleRequest,
  );
  yield takeLatest(types.LOGOUT_MANUAL, workerUnauthorize);
  yield takeLatest(types.AUTHORIZATION_SET_TOKEN_MANUAL, workerSetTokenManual);
}

export default function* auth2Sagas() {
  yield all([fork(watchAuthFlow)]);
}

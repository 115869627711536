import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import {
  Autocomplete,
  autocompleteFilter,
  TextField,
} from '@iclinic/design-system';

import { ProfessionalFormValues } from '../types';
import {
  SearchCBOList,
  getOption,
} from 'features/new-auth/stepSignup/state/types';
import { cboInputSelector } from '../state/selectors';

function CBOSelect({ disabled }: { disabled: boolean }) {
  const [inputValue, setInputValue] = useState('');

  const {
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormikContext<ProfessionalFormValues>();

  const cboList = useSelector(cboInputSelector);

  const checksetFieldValue = (newValue: SearchCBOList) => {
    if (newValue && newValue.inputValue) {
      return setFieldValue('cbo', newValue.inputValue);
    }

    if (newValue && newValue.term) return setFieldValue('cbo', newValue.term);

    return setFieldValue('cbo', '');
  };

  const filterOptionSearchBox = (options: SearchCBOList[], params: any) => {
    const customParams = { ...params, inputValue };
    return autocompleteFilter(options, customParams);
  };

  return (
    <Autocomplete
      id="cbo"
      value={values.cbo}
      onChange={(_, newValue: SearchCBOList) => checksetFieldValue(newValue)}
      onBlur={() => setFieldTouched('cbo', true)}
      options={cboList}
      filterOptions={filterOptionSearchBox}
      getOptionLabel={getOption}
      getOptionSelected={(option) => option.term === values.cbo}
      renderOption={(option) => option.term}
      inputValue={inputValue}
      onInputChange={(_, newInputValue: string) => setInputValue(newInputValue)}
      fullWidth
      disabled={disabled}
      noOptionsText="Sem opções"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Especialidade"
          name="cbo"
          type="text"
          placeholder="Ex: Dermatologista"
          autoComplete="cbo"
          margin="normal"
          error={!!(touched.cbo && errors.cbo)}
          helperText={touched.cbo && errors.cbo}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.cbo}
          disabled={disabled}
          data-ga="cfmupdate-cbo"
        />
      )}
    />
  );
}

export default CBOSelect;

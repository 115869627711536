import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  MaterialIcons,
} from '@iclinic/design-system';

import * as S from './styles';
import {
  isModalOpen,
  isOptInAccepted,
} from 'features/free-samples/state/opt-in-confirmation/selectors';
import {
  closeConfirmationModal,
  openOptinOffCanvas,
  openSuccessModal,
  sendIntercomEvent,
} from 'features/free-samples/state/opt-in-confirmation';

const { Laugh, Bell, Lock, Gift } = MaterialIcons;

const OptInConfirmationModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isModalOpen);
  const isOptInStatusAccepted = useSelector(isOptInAccepted);

  const handleCloseModal = () => {
    dispatch(closeConfirmationModal());
  };

  const handleSubmit = () => {
    dispatch(openOptinOffCanvas());
    dispatch(sendIntercomEvent('started_not_completed'));
  };

  if (isOptInStatusAccepted) {
    dispatch(openSuccessModal());
    handleCloseModal();
  }

  if (isOpen && !isOptInStatusAccepted) {
    dispatch(sendIntercomEvent('click_menu'));
  }

  return (
    <Dialog open={isOpen} onClose={handleCloseModal} maxWidth="sm">
      <DialogHeader
        onClose={handleCloseModal}
        title="Receba amostras grátis no seu consultório!"
      />

      <DialogContent dividers>
        <S.CardContainer>
          <S.Card>
            <S.IconWrapper>
              <Laugh />
            </S.IconWrapper>
            Produtos grátis para pacientes
          </S.Card>
          <S.Card>
            <S.IconWrapper>
              <Bell />
            </S.IconWrapper>
            Conheça as novidades do mercado
          </S.Card>
          <S.Card>
            <S.IconWrapper>
              <Lock />
            </S.IconWrapper>
            Seus dados seguros e protegidos
          </S.Card>
          <S.Card>
            <S.IconWrapper>
              <Gift />
            </S.IconWrapper>
            Serviço oferecido de forma grátis
          </S.Card>
        </S.CardContainer>
        <S.Body variant="sm">
          Oferecemos amostras grátis de forma moderna e confiável, com conteúdo
          sobre medicamentos e acesso direto ao representante do laboratório.
          Confirme sua participação e veja os medicamentos disponíveis.
        </S.Body>
        <S.BodyDescription variant="xs">
          Ao confirmar, você aceita nossos{' '}
          <a
            href="https://suporte.iclinic.com.br/pt-br/termo-de-autoriza%C3%A7%C3%A3o-e-consentimento-rx-pro"
            target="_blank"
            rel="noreferrer"
          >
            Termos de Uso
          </a>{' '}
          e pode cancelá-lo a qualquer momento.
        </S.BodyDescription>
      </DialogContent>

      <DialogActions>
        <Button color="transparent" onClick={handleCloseModal}>
          Agora não
        </Button>

        <Button
          onClick={handleSubmit}
          data-testid="freesamples-modal-cta"
          data-ga="free_samples_opt_in"
          data-sp="free_samples_opt_in"
        >
          Aceito receber amostras
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OptInConfirmationModal;

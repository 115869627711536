import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { Element } from 'react-scroll';
import { Box, MaterialIcons } from '@iclinic/design-system';

import { ScrollToErrorFormik } from 'shared/hooks';
import { save } from 'features/onlinePayment/state/config/register';
import { SellerForm } from 'features/onlinePayment/state/config/types';
import { trackSignupIClinicPayButtons } from 'features/onlinePayment/trackEvents/trackSignupIClinicPayEvents';
import { SellerType } from 'features/onlinePayment/containers/config/seller/constants';
import {
  getUserInfo,
  getSellerProfileSelector,
} from 'features/onlinePayment/state/config/register/selectors';
import SellerRegisterProfileLoader from 'features/onlinePayment/components/config/SellerRegisterProfileLoader';
import {
  CardAddress,
  CardProfile,
} from 'features/onlinePayment/components/config';
import CardClinicOwner from 'features/onlinePayment/components/config/clinicOwner/CardClinicOwner';
import SellerProfileMenu from 'features/onlinePayment/components/config/menu/SellerProfileMenu';
import * as S from 'features/onlinePayment/components/config/Fab';
import { getProfileFormInitialData } from './form.normalizer';
import { validationSellerRegisterProfile } from './validationSchema';
import useStyles from './SellerRegisterProfile.styles';

const { ArrowForward } = MaterialIcons;

const SellerRegisterProfile = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const userData = useSelector(getUserInfo);
  const {
    physician,
    fetching,
    saving,
    physicianId,
    fullyCreatedSeller,
    sellerForm: initialSellerForm,
    seller,
    sellerType,
  } = useSelector(getSellerProfileSelector);

  if (fetching || saving) {
    return <SellerRegisterProfileLoader />;
  }

  const initialProfileValues = getProfileFormInitialData({
    sellerForm: initialSellerForm,
    seller,
    physician,
    physicianId,
    sellerType,
  });

  const handleSubmit = () => {
    const buttonName =
      initialProfileValues.type === SellerType.PF
        ? 'pay_register_first_step_individual_person'
        : 'pay_register_first_step_legal_person';
    trackSignupIClinicPayButtons({
      userData,
      buttonName,
      buttonLocation: 'create_payment',
    });
  };

  return (
    <Formik
      initialValues={initialProfileValues}
      validationSchema={validationSellerRegisterProfile}
      onSubmit={(sellerForm: SellerForm) => {
        dispatch(save(sellerForm));
      }}
    >
      <Form>
        <div className={classes.container}>
          <SellerProfileMenu />
          <div className={classes.fields}>
            <Box mb={2}>
              <Element name="card-profile">
                <CardProfile />
              </Element>
            </Box>
            <Box mb={2}>
              <Element name="card-address">
                <CardAddress />
              </Element>
            </Box>
            <Box mb={2}>
              <Element name="card-clinic-owner">
                <CardClinicOwner />
              </Element>
            </Box>
            <S.FloatingWrapper>
              <S.Fab
                type="submit"
                onClick={handleSubmit}
                disabled={saving || fullyCreatedSeller}
                data-ga="pagamento-online-cadastro-continuar"
              >
                <ArrowForward />
                <span>Continuar</span>
              </S.Fab>
            </S.FloatingWrapper>
            <ScrollToErrorFormik />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default SellerRegisterProfile;

import {
  Grid,
  MaterialUICore,
  axiomTokens,
  Box as DSBox,
  Body as DSBody,
  Heading as DSHeading,
  MaterialIcons as Icons,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { font, color, spacing, shadowBrand, border } = axiomTokens;

export const SuccessPageContainer = styled(Grid)(({ theme }) => ({
  position: 'relative',
  background: `linear-gradient(180deg, ${color.background[1]} 74.54%, ${color.branding[150]} 100%)`,
  display: 'flex',
  alignItems: 'flex-start',
  minHeight: '100vh',
  width: '100%',
  flexDirection: 'column',

  [theme.breakpoints.down('lg')]: {
    paddingBottom: '70px',
  },
}));

export const CardInfoContainer = styled(Grid)(({ theme }) => ({
  display: 'grid',
  margin: '0 auto',
  width: '100%',
  gap: spacing[4],
  gridTemplateColumns: '1fr',

  [theme.breakpoints.up('md')]: {
    maxWidth: '1040px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(3, 336px)',
  },
}));

export const InfoCard = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '22.66px 15.1px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: shadowBrand.level[3],
  border: border.width.xs,
  borderRadius: border.radius.md,
  background: color.surface.subtle.default,
  width: '100%',
  textAlign: 'center',
  wordBreak: 'break-word',

  [theme.breakpoints.down('sm')]: {
    width: '90%',
    margin: '0 auto',
  },
}));

export const Strong = styled('strong')({
  color: color.highlight.blue[2],
  wordBreak: 'break-word',
});

export const CheckCircle = styled(Icons.CheckCircleRounded)({
  color: color.icon.activated,
  size: '30px',
});

export const Box = styled(DSBox)({
  paddingTop: spacing[5],
  paddingBottom: spacing[5],
  marginTop: spacing[6],
});

export const Heading = styled(DSHeading)({
  paddingTop: spacing[5],
  paddingBottom: spacing[5],
  marginTop: spacing[3],
  marginBottom: spacing[3],
  fontSize: font.size.md,
});

export const Body = styled(DSBody)({
  fontSize: font.size.xs,
  letterSpacing: '0.44px',
  lineHeight: '24px',
});

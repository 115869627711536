import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Response } from '@types';

import * as actions from '.';
import * as services from '../../services';
import {
  OptInResponse,
  Pharmaceutical,
  PharmasResponse,
} from '../../services/types';
import { getSelectedPharmas } from './selectors';

type SendIntercomEventPayload = ReturnType<typeof actions.sendIntercomEvent>;

export function* setOptInConfirmationWorker() {
  try {
    const selectedPharmas: Pharmaceutical[] = yield select(getSelectedPharmas);
    const selectedPharmasIds = selectedPharmas.map((pharma) => pharma.id);
    const response: Response<OptInResponse> = yield call(
      services.setFreeSamplesOptIn,
      selectedPharmasIds,
    );

    if (response) {
      yield put(actions.submitOptInConfirmationSuccess());
    } else {
      yield put(actions.submitOptInConfirmationFailure());
    }
  } catch (error) {
    yield put(actions.submitOptInConfirmationFailure());
  }
}

export function* fetchPharmasDataWorker() {
  try {
    yield put(actions.fetchPharmasStart());

    const { data }: Response<PharmasResponse> = yield call(services.getPharmas);

    yield put(actions.fetchPharmasSuccess(data.objects));
  } catch (error) {
    yield put(actions.fetchPharmasFailure());
  }
}

export function* sendIntercomEventWorker({
  payload: event,
}: SendIntercomEventPayload) {
  try {
    const response: Response<OptInResponse> = yield call(
      services.sendIntercomEvent,
      event,
    );

    if (response) {
      yield put(actions.sendIntercomEventSuccess(event));
    } else {
      yield put(actions.sendIntercomEventFailure());
    }
  } catch (error) {
    yield put(actions.sendIntercomEventFailure());
  }
}

export default function* freeSamplesOptInSagas() {
  yield takeLatest(actions.setOptInConfirmation, setOptInConfirmationWorker);
  yield takeLatest(actions.fetchPharmasData, fetchPharmasDataWorker);
  yield takeLatest(actions.sendIntercomEvent, sendIntercomEventWorker);
}
